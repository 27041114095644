const list = (listSelector) => {
  const id_precatalog = document.querySelector(listSelector);
  const select_catalog = document.querySelector('.catalog');
  const obj = document.querySelector('.data-json').getAttribute('data-json');
  const data = JSON.parse(decodeURIComponent(obj));

  if (data) {
    const objr = document.querySelector('.data-json');
    objr.removeAttribute('data-json');
  }

  if (select_catalog.options.length === 1) {
    let list = document.getElementById('id').value;
    data.forEach(item => {
      if (item.id_precatalog === list) {
        select_catalog.innerHTML += `<option value="${item.id}">${item.title}</option>`;
      }
    });
  }

  id_precatalog.addEventListener('change', () => {
    let list = document.getElementById('id').value;
    select_catalog.options.length = 0;
    if (list != 0 || list != null) {
      data.forEach(item => {
        if (item.id_precatalog === list) {
        select_catalog.innerHTML += `<option value="${item.id}">${item.title}</option>`;
        }
      });
    } else {
      select_catalog.innerHTML += `<option value="${list} disabled" >Выберите из списка</option>`;
    }
  });
};

export default list;
