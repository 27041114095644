const hamburger = (listSelector, itemSelector, hamburgerSelector) => {

    const menu = document.querySelector(listSelector),
      menuItem = document.querySelectorAll(itemSelector),
      hamburger = document.querySelector(hamburgerSelector);

    hamburger.addEventListener('click', () => {
      hamburger.classList.toggle('header__hamburger_active');
      menu.classList.toggle('menu_active');
    });

    menuItem.forEach(item => {
      item.addEventListener('click', () => {
        hamburger.classList.toggle('header__hamburger_active');
        menu.classList.toggle('menu_active');
      })
    })
}

export default hamburger;
