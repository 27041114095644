const validation = (formErrorSelector) => {
  const form = document.querySelector("form");
  const formArr = Array.from(form);
  const validFormArr = [];
  const button = document.querySelector(".button_submit");

  const formError = formErrorSelector;
  const infoError = document.querySelector('.form__err');
  const nameError = document.querySelector('#login + span.' + formError);
  const emailError = document.querySelector('#mail + span.' + formError);
  const password1Error = document.querySelector('#password1 + span.' + formError);
  const password2Error = document.querySelector('#password2 + span.' + formError);
  const feedbackNameError = document.querySelector('#name + span.' + formError);
  const feedbackError = document.querySelector('#feedback + span.' + formError);
  const info = document.querySelector('.form__err');
  const checkboxValidError = document.querySelector('#comment-respond-consent + span.' + formError);

  formArr.forEach((el) => {
    if (el.hasAttribute("data-reg")) {
      el.setAttribute("is-valid", "0");
      validFormArr.push(el);
    }
  });

  form.addEventListener("input", inputHandler);
  button.addEventListener("click", buttonHandler);

  function inputHandler({ target }) {
    if (target.hasAttribute("data-reg")) {
      inputCheck(target);
      textError(target);
    }
  }

  function inputCheck(el) {
    const inputValue = el.value;
    const inputReg = el.getAttribute("data-reg");
    const reg = new RegExp(inputReg);
    if (reg.test(inputValue)) {
      el.setAttribute("is-valid", "1");
      el.style.border = "2px solid rgb(0, 196, 0)";
    } else {
      el.setAttribute("is-valid", "0");
      el.style.border = "2px solid rgb(235, 105, 105)";
    }
  }

  function textError(el) {
    const inputValue = el.value;
    const inputReg = el.getAttribute("data-reg");
    const reg = new RegExp(inputReg);
    let password1Value = '';
    let password2Value = '';

    if (reg.test(inputValue)) {
      if (el.getAttribute('id') === "login") {
        nameError.innerHTML = '';
        nameError.className = formError;
      }
      if (el.getAttribute('id') === "mail") {
        emailError.innerHTML = '';
        emailError.className = formError;
      }
      if (el.getAttribute('id') === "password1") {
        password1Error.innerHTML = '';
        password1Error.className = formError;
      }
      if (el.getAttribute('id') === "name") {
        feedbackNameError.innerHTML = '';
        feedbackNameError.className = formError;
      }
      if (el.getAttribute('id') === "feedback") {
        feedbackError.innerHTML = '';
        feedbackError.className = formError;
      }

    } else {
      if (el.getAttribute('id') === "login") {
        nameError.className = formError + ' active';
        nameError.textContent = 'Логин содержит не менее 2-х символов.';
      }
      if (el.getAttribute('id') === "mail") {
        emailError.className = formError + ' active';
        emailError.textContent = 'Введите действующий адрес электронной почты.';
      }
      if (el.getAttribute('id') === "password1") {
        password1Error.className = formError + ' active';
        password1Error.textContent = 'Пароль должен содержать строчные и прописные латинские буквы, цифры. Минимум 8 символов.';
      }
      if (el.getAttribute('id') === "name") {
        feedbackNameError.className = formError + ' active';
        feedbackNameError.textContent = 'Имя должно содержать не менее 2-х символов.';
      }
      if (el.getAttribute('id') === "feedback") {
        feedbackError.className = formError + ' active';
        feedbackError.textContent = 'Отзыв должен содержать не менее 5 и не более 200 слов';
      }
    }

    if (document.getElementById('password2')) {
      password1Value = document.getElementById('password1').value;
      password2Value = document.getElementById('password2').value;

      if (password1Value === password2Value) {
        if (el.getAttribute('id') === "password2") {
          password2Error.innerHTML = '';
          password2Error.className = formError;
        }
      } else {
        if (el.getAttribute('id') === "password2") {
          password2Error.className = formError + ' active';
          password2Error.textContent = 'Пароли в обеих полях должны соответствовать!';
        }
      }
    }

    if (el.getAttribute('id') === "comment-respond-consent") {
      if (document.getElementById('comment-respond-consent').checked) {
        el.setAttribute("is-valid", "1");
        checkboxValidError.innerHTML = '';
        checkboxValidError.className = formError;
      } else {
        checkboxValidError.className = formError + ' active';
        checkboxValidError.textContent = 'Для отправки необходимо принять условия работы сайта, поставив галочку!';
      }
    }
  };

  function buttonHandler(event) {
    const allValid = [];
    info.textContent = '';
    validFormArr.forEach((el) => {
      allValid.push(el.getAttribute("is-valid"));
    });


    const isAllValid = allValid.reduce((acc, current) => {
      return acc && current;
    });

    if (!Boolean(Number(isAllValid))) {
      info.textContent = 'Не все поля заполнены!';
      event.preventDefault();
    }
  }
};

export default validation;
