const filter = (btnSelector, catalogSelector, productsSelector) => {
  const btn = document.querySelector(btnSelector),
    products = document.querySelector(productsSelector);

  btn.addEventListener('click', () => {
    const list = document.querySelector(catalogSelector);
    list.style.display = 'block';
    products.style.display = 'none';
    list.classList.add('animated', 'fadeIn');
  });
}

export default filter;
