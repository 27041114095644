const page = (catalogSelector) => {
  const page = (new URL(document.location)).searchParams;
  const section = page.get('section');
  const catalog = document.querySelector(catalogSelector);
  if (section) {
    const sectionSelector = document.querySelector('.catalog_' + section);
    sectionSelector.style.display = 'block';
    catalog.style.display = 'none';
  }
};

export default page;
