const modals = () => {
  let btnPressed = false;
  function bindModal(triggerSelector, modalSelector, closeSelector, destroy = false) {
    const trigger = document.querySelectorAll(triggerSelector),
      modal = document.querySelector(modalSelector),
      close = document.querySelector(closeSelector),
      windows = document.querySelectorAll('[data-modal]');
    scroll = calcScroll();

    trigger.forEach(item => {
      item.addEventListener('click', (e) => {
        const buttonTarget = () => {
          if (e.target !== document.querySelector('.button_submit')) {
            e.preventDefault();
          }

          btnPressed = true;

          if (destroy) {
            item.remove();
          }

          windows.forEach(item => {
            item.style.display = 'none';
            item.classList.add('animated', 'fadeIn')
          });

          modal.style.display = "block";
          document.body.style.overflow = "hidden";
          document.body.style.marginRight = `${scroll}px`;
        }

        // Кнопка в комментариях
        if (item.className == 'blog__comments') {
          if (e.target.closest('button')) {
            buttonTarget();
          }
        }

        // Кнопка на странице
        if (item.className !== 'blog__comments') {
          buttonTarget();
        }
      });
    });

    if (close) {
      close.addEventListener('click', () => {
        windows.forEach(item => {
          item.style.display = 'none';
        });

        modal.style.display = "none";
        document.body.style.overflow = "";
        document.body.style.marginRight = `0px`;
      });

      modal.addEventListener('click', (e) => {
        if (e.target === modal) {
          windows.forEach(item => {
            item.style.display = 'none';
          });

          modal.style.display = "none";
          document.body.style.overflow = "";
          document.body.style.marginRight = `0px`;
        }
      });
    }
  }

  // function showModalByTime(selector, time) {
  //   setTimeout(function () {
  //     let display;

  //     document.querySelectorAll('[data-modal]').forEach(item => {
  //       if (getComputedStyle(item).display !== 'none') {
  //         display = 'block';
  //       }

  //       if (!display) {
  //         document.querySelector(selector).style.display = 'block';
  //         document.body.style.overflow = "hidden";
  //         let scroll = calcScroll();
  //         document.body.style.marginRight = `${scroll}px`;
  //       }
  //     });
  //   }, time);
  // }

  function calcScroll() {
    let div = document.createElement('div');

    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
  }

  // function openByScroll(selector) {
  //   window.addEventListener('scroll', () => {
  //     let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);

  //     if (!btnPressed && (window.pageYOffset + document.documentElement.clientHeight >= scrollHeight)) {
  //       document.querySelector(selector).click();
  //     }
  //   });
  // }

  // bindModal('.button_feedbacks', '.popup_feedbacks', '.popup_feedbacks .popup__close_feedbacks');
  // bindModal('.button_feedbacks', '.popup_feedbacks', '.popup_feedbacks .popup__close_feedbacks');
  bindModal('.floating-buttons__call', '.popup_consultation', '.popup_consultation .popup__close');
  bindModal('.blog__comments', '.popup_feedbacks', '.popup_feedbacks .popup__close_feedbacks');
  bindModal('.answer_button', '.popup_feedbacks', '.popup_feedbacks .popup__close_feedbacks');
  // bindModal('.button_comments_send', '.popup_feedbacks', '.popup_feedbacks .popup__close_feedbacks');
  // bindModal('.fixed-gift', '.popup-gift', '.popup-gift .popup-close', true);


  // openByScroll('.fixed-gift');
  // showModalByTime('.popup-consultation', 5000);
};

export default modals;
