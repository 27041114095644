import modals from './modules/modals';
import scrolling from './modules/scrolling';
import hamburger from './modules/hamburger';
import sliders from './modules/sliders';
import filter from './modules/filter';
import tabs from './modules/tabs';
import page from './modules/page';
import create from './modules/create';
import editor from './modules/editor';
import validation from './modules/validation';
import getname from './modules/getname';
import list from './modules/list';
import accordion from './modules/accordion';
// import captcha from './modules/captcha';

window.addEventListener('DOMContentLoaded', () => {
  'use strict';

  const slaiderSelector = document.querySelector('.slider');
  const catalogSelector = document.querySelector('.catalog_catalog');
  const blogSelector = document.querySelector('.blog');
  const rowSelector = document.querySelector('.row');
  const formSelector = document.querySelector('.form');
  const addRecord = document.querySelector('.add_record');
  const editorSelector = document.querySelector('#editor');
  const getNameSelector = document.querySelector('.form__input-group');
  const selectorList = document.querySelector('.posts_product');
  const partnershipSelector = document.querySelector('.partnership');

  if (partnershipSelector) {
    accordion();
  }

  if (slaiderSelector) {
    sliders('.slider__item', 'horizontal', '.slider__btn_prev', '.slider__btn_next');
  }

  if (rowSelector) {
    tabs('.sidebar__list', '.sidebar__item', '.posts', 'active');
  }

  if (addRecord) {
    create('.posts__link', '.add', '.posts', '.sidebar__item');
  }

  if (editorSelector) {
    editor();
  }

  if (catalogSelector) {
    filter('.button_health', '.catalog_health', '.catalog_catalog');
    filter('.button_beauty', '.catalog_beauty', '.catalog_catalog');
    filter('.button_hygiene', '.catalog_hygiene', '.catalog_catalog');
    filter('.button_home', '.catalog_home', '.catalog_catalog');
    filter('.button_food', '.catalog_food', '.catalog_catalog');
    filter('.button_textile', '.catalog_textile', '.catalog_catalog');
    filter('.button_tea', '.catalog_tea', '.catalog_catalog');
    filter('.button_accessories', '.catalog_accessories', '.catalog_catalog');
    tabs('.nav__submenu', '.nav__item', '.catalog', 'nav__link_active');
  }

  if (blogSelector) {
    // tabs('.nav__list', '.nav__link', '.blog', 'nav__link_active');
    // paginate();
    // paginate('button-prev', 'button-next', 'page-number', 'pagination__page-numbers-current');
    // pagination('button-prev', 'button-next', 'page-number', 'pagination__page-numbers-current', '.blog__main_contents', '.blog__main_content', '.listing__post', '.item__post', '.blog__comments', '.comments__button');
    // captcha();
  }

  if (formSelector) {
    validation('form__error');
  }

  if (getNameSelector) {
    getname();
  }

  if (selectorList) {
    list('.list');
  }

  modals();
  scrolling('.floating-buttons__link', '.nav');
  hamburger('.menu', '.menu__item', '.header__hamburger');
  page('.catalog_catalog');
});
