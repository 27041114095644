const getname = () => {
  let fields = document.querySelectorAll('.field__file');
  // fields.style.opacity = 0;
  Array.prototype.forEach.call(fields, function (input) {
    let label = input.nextElementSibling,
      labelVal = label.querySelector('.field__file-fake').innerText;
      if (!labelVal) {
        labelVal = 'Файл не выбран';
        label.querySelector('.field__file-fake').innerText = labelVal;
      }
      // console.log('label: ' + label);
      // console.log('labelVal: ' + labelVal);

    input.addEventListener('change', function () {
      let nameFiles = '';

      if (this.files && this.files.length >= 1) {
        nameFiles = this.files[0].name;
        // console.log('Это в случае ввода файла countFiles: ' + nameFiles);

        if (nameFiles) {
          label.querySelector('.field__file-fake').innerText = nameFiles;
          // console.log('Это в случае получения файла nameFiles: ' + nameFiles);
        } else {
          label.querySelector('.field__file-fake').innerText = labelVal;
          // console.log('Это в случае отсутствия файла labelVal: ' + labelVal);
        }
      }
    });
  });
};

// const getname = () => {
//   let fields = document.querySelectorAll('.field__file');
//   Array.prototype.forEach.call(fields, function (input) {
//     let label = input.nextElementSibling,
//       labelVal = label.querySelector('.field__file-fake').innerText;
//       console.log(labelVal);

//     input.addEventListener('change', function () {
//       let countFiles = '';

//       if (this.files && this.files.length >= 1) {
//         countFiles = this.files[0].name;

//         if (countFiles) {
//           label.querySelector('.field__file-fake').innerText = countFiles;
//         } else {
//           label.querySelector('.field__file-fake').innerText = labelVal;
//         }
//       }
//     });
//   });
// };

export default getname;
