const create = (btn, addSelector, tab, sidebarSelector) => {
  const btnElement = document.querySelectorAll(btn);
  const add = document.querySelectorAll(addSelector);
  const tabElement = document.querySelectorAll(tab);
  const itemSidebar = document.querySelectorAll(sidebarSelector);

  for (let i = 0; i < tabElement.length; i++) {
    btnElement[i].addEventListener("click", function () {
      tabElement[i].style.display = 'none';
      for (let a = 0; a < add.length; a++) {
        if (i === 0) {
          add[0].style.display = 'block';
        }
        if (i === 1) {
          add[1].style.display = 'block';
        }
        if (i === 2) {
          add[2].style.display = 'block';
        }
        if (i === 3) {
          add[3].style.display = 'block';
        }
        if (i === 4) {
          add[4].style.display = 'block';
        }
        for (let j = 0; j < itemSidebar.length; j++) {
          itemSidebar[j].addEventListener('click', function () {
            add[a].style.display = 'none';
          });
        }
      }
    });
  };
};

export default create;
